/* 

-------------------------------------------------------------

TOUR

*/

.tour {
	height:540px;
	padding:0px;
	background:#000000;
	background-position:top center;
	background-size:cover;
	position:relative;

	.description {
		height:135px;
		width:550px;
		position:absolute;
		left:0px;
		right:0px;
		top:0px;
		bottom:0px;
		margin:auto;

		@include breakpoint(small) { 
			width:280px;
			height:200px;
			text-align:center;
		}
	}

	.play-video {
		position:absolute;
		top:0px;
		bottom:0px;
		margin:auto;
		z-index:10;
		height:120px;
		width:120px;

		@include breakpoint(small) { 
			top:auto;
			bottom:auto;
			position:relative;
			margin:auto;
			margin-bottom:30px;
		}
	}

	.text {
		position:absolute;
		left:150px;
		top:25px;
		color:#ffffff;

		@include breakpoint(small) { 
			left:auto;
			top:auto;
			position:relative;
			margin:auto;
		}
	}

	h2 {
		font-size:32px;
		line-height:36px;
	}
}