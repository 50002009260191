/*

-------------------------------------------------------------

MODAL

*/

.modal {
	@include transition(all 0.75s $easeOutQuint);
	background:rgba(0,0,0,0.9);
	position:absolute;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	z-index: 999;
	overflow:auto;
	visibility:hidden;
	pointer-events: none;
	opacity:0;
	-webkit-overflow-scrolling: touch;
    .modal-close{
        position: absolute;
        font-weight: $light;
        font-size: 50px;
        top:-50px;
        right:0px;
        cursor:pointer;
        display: block;
    }
	.content {
		@include transition(all 0.75s $easeOutQuint);
		padding:200px 0px 50px 0;
		margin-top:-50px;
		text-align:center;
		position: relative;
		top: 50%;
		@include breakpoint(small) { 
			top: 50%;
			padding:0px 0px; 
		}
	}
}

.modal-open {
	visibility:visible;
	opacity:100;
	pointer-events: auto;
	.close {
		//top:40px;

		//@include breakpoint(small) { top:20px; }
	}

	.logo {
		top:35px;

		@include breakpoint(small) { top:18px; }
	}

	.content { margin-top:0px; }
}

.body-modal-open {
	height: 100%;
	z-index: 1;
}

.plan-dl-disclaimer {
	color: white;
}
