/* TINY HOMES and MODEL VILLAGE layouts */

.featurepage{
  .container{
    @include clearfix;

  }
  &.bottom{
    padding-bottom:60px;
  }
 .third{
   width:40%;
   margin-bottom:80px;
   padding:16px;
   position:relative;
   float: left;
   display: inline-block;
   @include breakpoint(small){
     width: 100%;
     margin-bottom:30px;
   }

  }

  .twothirds{
    margin-bottom:80px;
    width:60%;
    padding:16px;
    float: left;
    display: inline-block;
     position:relative;
     &.pic{
       padding-left: 80px;
     }
     &.pic.right{
       padding-left: 16px;
       padding-right: 80px;
     }
     &.text{
       padding-right: 80px;
     }
     &.text.right{
       padding-right: 16px;
       padding-left: 80px;
     }
     @include breakpoint(small){
       width: 100%;
       margin-bottom:30px;
       &.pic{
         padding-left: 16px;
       }
       &.pic.right{
         padding-left: 16px;
         padding-right: 16px;
       }
       &.text{
         padding-right: 16px;
       }
       &.text.right{
         padding-right: 16px;
         padding-left: 16px;
       }
     }
  }
  .th-third {
    width:40%;
    padding:16px;
    position:relative;
    float: left;
    display: inline-block;
    @include breakpoint(small){
      width: 100%;
      margin-bottom:30px;
    }
   }
  .th-twothirds{
    margin-bottom:80px;
    width:60%;
    padding:16px;
    float: left;
    display: inline-block;
     position:relative;
     &.pic{
       padding-right: 80px;
     }
     &.pic.right{
       padding-right: 16px;
       padding-left: 80px;
     }
     &.text{
       padding-left: 80px;
     }
     &.text.right{
       padding-left: 16px;
       padding-right: 80px;
     }
     @include breakpoint(small){
       width: 100%;
       margin-bottom:30px;
       &.pic{
         padding-right: 16px;
       }
       &.pic.right{
         padding-right: 16px;
         padding-left: 16px;
       }
       &.text{
         padding-left: 16px;
       }
       &.text.right{
         padding-left: 16px;
         padding-right: 16px;
       }
     }
  }
  .right{float:right;}

}
.nopad{padding:0;}
.designcenter{
  text-align: center;
  z-index: 3;
}
.designimage{
  padding: 0;
  z-index: 1;
  margin-top:-250px;
  background-image: url('../img/designcenter.jpg');
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  @include aspect-ratio(225, 116);
  @include breakpoint(small){
    margin-top:0;
  }
  &.eagle-river{
    margin-top:-200px;
    background-position: center bottom;
    background-image:url('../eagle-river-design.jpg');
    @include breakpoint(small){
      margin-top:0;
    }
  }
  &.factory{
    margin-top:-200px;
    background-position: center bottom;
    background-image:url('../factory-hero.jpg');
    @include breakpoint(small){
      margin-top:0;
    }
  }
}
.video-play{
  height:60px;
  width:60px;
  background: rgba($red, 0.95) url(../img/playbg.png) center no-repeat;
  box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.27);
  border-radius: 100%;
  position: relative;
  display: inline-block;
  transition:.27s;
  cursor: pointer;
  background-size: 100%;
  @include breakpoint (small){
    // height: 80px;
    // width:80px;
  }
  &:hover{
      background: $red url(../img/playbg.png) center no-repeat;
      background-size: 100%;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.27);
  }
}
.calloutfeattext{
  width:100%;
  position: relative;
  padding:0;

  background-size:cover;
  background-repeat:no-repeat;
  background-position: center;
  padding:120px;
  .content{
    padding:100px 200px;
    text-align: center;
    color: white;
    background:rgba($red-dark, .90);
    position: relative;

  }
  @include breakpoint(small){
    padding:20px;
    .content{
      padding:16px;
    }
  }
}

.calloutfull {
  width: 100%;
  position: relative;
  padding: 0;
  a {
    display: block;
  }
}

.calloutimage {
  height: 800px;
  background-size:cover;
  background-repeat:no-repeat;
  background-position: center center;
  @include breakpoint(small) {
    height: 400px;
  }
}

.modelhero{
  background: url('../img/model-hero.jpg') center bottom no-repeat;
  background-size: cover;
  text-align: center;
  height: 960px;
  .modelherocontent{
    margin: 300px auto;
    max-width: 600px;
    width:100%;
    h1{font-size: 52px;  line-height:1.7;}
    p{text-align: left;}
  }
  @include breakpoint(small){
    height: 700px;
    .modelherocontent{

      margin:150px auto;
      padding:16px;
      h1{font-size: 30px;}
    }
  }
}
  .featmodel{
    width:33.333%;
    float: left;
    text-align: center;
    background: grey;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include aspect-ratio(60, 61);
    .content{
      padding-top:80px;
      color:white;
      text-align: center;
      h2{margin-bottom: 20px;font-size: 40px;}
      a{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        .btn{
          box-shadow: 0px 10px 60px 20px rgba(black, 0.37);
          opacity: 0;
          width: 230px;
        //  padding-left: 36px;
          height: 60px;
          margin: auto;
          position: absolute;
          text-align: center;
          position: absolute;
          top:0;
          left: 0;
          right: 0;
          left: 0;
          bottom:0;
          margin:auto;

        }
        &:hover{
          .btn{opacity: 1;}
        }
      }
      .line{
        display: inline-block;
        margin:0 auto;
        position: absolute;
        left: 0;
        right:0;
        //margin-top:20px;
      }
    }
    @include breakpoint(large) {
      width:50%;
    }
    @include breakpoint(small){
      width:100%;
      .content a .btn{opacity: 1;}
    }
  }

  .video-slide-wrapper{
    overflow: hidden;
    .play-video{

    //  opacity: .5;
      img{
        box-shadow: none!important;
      }
    }
    h3{color:white;}
    .owl-house-gallery .owl-item.active img {
    box-shadow: 0px 0px 55px 20px rgba(0, 0, 0, 0.29);
}
  }

  .video-section {
    text-align: center;
  }
