/*

-------------------------------------------------------------

VARIABLES

*/

// Font weights
$light 	 : 300;
$regular : 400;
$medium  : 500;
$bold    : 700;

// Colors
$red : #c7202e;
$red-dark : #a91b27;
$primary : $red;
$brand : $red;
// Primary Font
$primary-font-family : 'Droid Sans', sans-serif;
$primary-font-weight : $regular;
$primary-font-size   : 16px;
$primary-font-color  : #414141;
$primary-link-color  : $red;
$primary-line-height : 26px;

// Primary Font
$secondary-font-family : 'Quicksand', sans-serif;
$secondary-font-weight : $regular;

// Transitions
$default-transition-property : all;
$default-transition-duration : 1s;
$default-transition-function : false;
$default-transition-delay    : false;

// Easing
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
