/*

-------------------------------------------------------------

FOOTER

*/
.social{
	margin-bottom:100px;
}
.custom-icon{
	margin:7px;
	@include breakpoint(small){
		margin:2px;
	}
}
footer {
	text-align:center;
	color:#989898;

	a { color:#989898; }

	a:hover { color:#ffffff; }

	.newsletter {
		margin-bottom:100px;
		text-align:left;
	}

	.newsletter-txt {
		@extend .borderbox;
		font-family:$secondary-font-family;
		color:#8d8d8d;
		font-size:14px;
		letter-spacing:1px;
		background:#404040;
		border:none;
		height:60px;
		float:left;
		width:70%;
		padding:0px 30px;

		@include breakpoint(small) {
			width:100%;
			float:none;
			margin-bottom:15px;
		}
	}

	.newsletter-btn {
		@extend .borderbox;
		@include transition(all 0.2s);
		font-family:$secondary-font-family;
		color:#ffffff;
		font-size:14px;
		background:$red;
		border:none;
		height:60px;
		float:right;
		width:28%;
		cursor:pointer;

		@include breakpoint(small) {
			width:100%;
			float:none;
		}
	}

	.newsletter-btn:hover {
		background:lighten($red, 5%);
	}

	.footer-top {
		background:#323232;
		padding-top:120px;
		padding-bottom:50px;

		b { color:#ffffff; }
	}

	.footer-bottom {
		text-align:left;
		background:#212121;
		padding:50px 0px;
		font-size:14px;
	}

	.row .column-4 {
		text-align:left;
		font-size:14px;
		border-left:1px solid #404040;
		padding-left:30px;
		min-height:100px;

		@include breakpoint(small) { width:100%; border-left:0px;}
	}

	.logo {
		display:inline-block;
		width:200px;
	}

	h3 { color:#ffffff; }

	.line { margin-left:auto; margin-right:auto; }
}
