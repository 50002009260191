/*

-------------------------------------------------------------

PHILOSOPHY

*/
.video-slide-wrapper{
	padding: 75px 120px;
	@include breakpoint(medium) { padding: 75px 80px; }

	@include breakpoint(small) { padding: 30px 20px;}
	.owl-carousel .owl-stage-outer {
		overflow: visible;
		.owl-item {
			opacity: .37;
			&.active{
				opacity: 1;
				box-shadow: 0px 0px 35px rgba(black, .55);
				@include breakpoint(small) {
					box-shadow: 0px 0px 14px rgba(black, .55);
				}
			}
		}
	}
}
.owl-video-tn{
  @include aspect-ratio(16, 9);

}


.philosophy {

	.video-slider {
		position:relative;
		height:480px;
		margin-top:100px;
		text-align:center;

		@include breakpoint(large) { height:425px; }

		@include breakpoint(medium) { height:355px; }

		@include breakpoint(small) { display:none; }
	}

	.slider-nav {
		font-weight:bold;
		font-family:$secondary-font-family;
		text-align:right;
		font-size:12px;
		position:absolute;
		right:190px;
		bottom:30px;
		z-index:50;
		color:#ffffff;

		@include breakpoint(large) { right:100px; }

		a {
			color:#ffffff;
			display:inline-block;
		}

		.previous {
			margin-left:20px;
			margin-right:10px;
		}

		span.slide-title {
			span {
				color:$red;
				margin:0px 10px;
			}
		}
	}

	.slide {
		@include transition(all 0.75s $easeOutQuint);
		@include transform(scale(1));
		position:absolute;
		left:160px;
		background:#323232;
		width:880px;
		height:100%;
		color:#ffffff;
		z-index:10;

		@include breakpoint(large) {
			width:780px;
			height:425px;
			left:60px;
		}

		@include breakpoint(medium) {
			width:650px;
			height:355px;
			left:25px;
		}

		h3 {
			text-align:left;
		}

		img {
			opacity:0.5;
		}
	}

	.slide-prev, .slide-next {
		@include transform(scale(0.85));
		opacity:0.3;
		z-index:5;
	}

	.slide-prev {
		left:-70px;
	}

	.slide-next {
		left:385px;

		@include breakpoint(large) {
			left:185px;
		}

		@include breakpoint(large) {
			left:120px;
		}
	}

	.play-video {
		position:absolute;
		//left:160px;
		left:0;
		right:0;
		top:0px;
		bottom:0px;
		margin:auto;
		z-index:10;
		height:120px;
		width:120px;
		border-radius:1000px;
		opacity: .4;
		@include transition (.3s);
		svg{fill:#fff;@include transition (.3s);}

		&:hover{
			opacity: .8;
			//svg{fill:$red;}
		}
	//	@include breakpoint(large) { left:120px; }
	}

	.text {
		position:absolute;
		height:70px;
		width:100%;
		padding:0 100px;
		top:0px;
		bottom:0px;
		margin:auto;
		z-index:10;
		pointer-events: none;

		@include breakpoint(large) { left:260px; }
	}

	h3 { text-align:center; }

	.line { margin-left:auto; margin-right:auto; }
}
