.preorder-hero {
  @include screen-below(700px) {
    height: 750px !important;
  }
  @include screen-between(700px, 861px) {
    height: 850px !important;
  }
  @include screen-above(861px) {
    height: 950px !important;
  }
}

.preorder-text {
  @include screen-below(700px) {
    top: 50px !important;
  }
  @include screen-between(700px, 861px) {
    top: 100px !important;
  }
  @include screen-above(861px) {
    top: 260px !important;
  }
}

.preorder-form {
  position: absolute;
  width: 100%;
  max-width: 500px;
  left: 0;
  right: 0;
  margin: auto;
  @include screen-below(700px) {
    top: 275px;
  }
  @include screen-between(700px, 861px) {
    top: 250px;
  }
  @include screen-above(861px) {
    top: 430px;
  }
}

.preorder-input {
  color: #404040;
  background: white;
}

.honeypot {
  display: none;
}

.formResponse {
  color: white;
}

.plan-submit {
  color: white;
  background: #c7202e;
  cursor: pointer;
}
