/*

-------------------------------------------------------------

HOMES

*/

.homes {
	font-size:14px;

	.overview {
		height:440px;
		position:relative;
		padding-left:710px;
		padding-right:40px;
		padding-top:80px;
        &.service{padding-top:50px;}

		@include breakpoint(large) {
			height:370px;
			padding-left:610px;
			padding-top:0px;
		}

		@include breakpoint(medium) {
			padding-left:390px;
		}

		@include breakpoint(small) {
			padding:0px;
			height:auto;
			margin-bottom:50px;
		}

		.callout {
			position:absolute;
			top:0px;
			left:0px;
			width:630px;

			@include breakpoint(large) { width:530px; }

			@include breakpoint(medium) {
				width:400px;
				top:91px;
				left:-100px;
			}

			@include breakpoint(small) {
				width:auto;
				position:relative;
				left:auto;
				top:auto;
			}
		}

		.text {
			@include breakpoint(small) {
				padding:30px;
			}
		}
	}
    .interiors{
        background:#302f2f;
        color:#989898;
        height: 440px;

        h3 { color:#ffffff; }
    }
    .services-left {
        height:auto;
        padding-bottom:100px;
        .callout {

			top:150px!important;

            &.reflect{
                top:240px!important;
                right:-320px;
                @include breakpoint(small) {display:none;}
            }
            @include breakpoint(small){ top:0px!important; }
        }


    }
    .interiors.int2{
        margin-left:380px!important;
        padding-right:15px;
        padding-left:320px;
        @include breakpoint(large) {
			width:720px;
			padding-left:370px;
             margin-left:00px!important;
		}

		@include breakpoint(small) {
			width:auto;
			padding:0px;
			height:auto;
			margin-bottom:50px;
		}
        .callout {
            left:-380px!important;
            right:auto;
             @include breakpoint(medium) { left:-180px!important; }
            @include breakpoint(small){ left:0px!important; }
        }
    }
	.interiors, .services-left {
		width:820px;
		position:relative;
		padding-right:340px;
		padding-left:80px;
		padding-top:80px;
		@include breakpoint(large) {
			width:720px;
			padding-right:370px;
		}

		@include breakpoint(small) {
			width:auto;
			padding:0px;
			height:auto;
			margin-bottom:50px;
		}

		//h3 { color:#ffffff; }

		.callout {
			position:absolute;
			bottom:-90px;
			right:-380px;
			width:630px;

			@include breakpoint(large) {
				width:490px;
				right:-180px;
			}

			@include breakpoint(small) {
				width:auto;
				position:relative;
				right:auto;
				bottom:auto;
			}
		}

		.text {
			@include breakpoint(small) {
				padding:30px;
			}
		}
	}

	.specifications {
		position:relative;
		padding-left:710px;
		padding-right:40px;
		padding-top:180px;
		padding-bottom:50px;

		@include breakpoint(large) { padding-left:490px; }

		@include breakpoint(medium) { padding-left:390px; }

		@include breakpoint(small) {
			padding:0px;
			height:auto;
		}

		.callout {
			position:absolute;
			top:0px;
			left:0px;
			width:630px;

			@include breakpoint(large) { width:410px; }

			@include breakpoint(medium) { left:-100px; }

			@include breakpoint(small) {
				position:relative;
				width:auto;
				top:auto;
				left:auto;
			}
		}

		.text {
			@include breakpoint(small) {
				padding-top:30px;
			}
		}
        &.spec2{
            padding-right:710px;
            padding-left:30px;
            padding-bottom: 100px;
            @include breakpoint(medium) {
				padding-right:0px;
			}
            .callout{
                right:-60px; left:auto;
                @include breakpoint(medium) {
                    right:-20px;
                }
                @include breakpoint(small) {display:none;}
            }
        }
	}

	.line {
		margin:30px 0px;
	}
}

.model {
	background: #323232 !important;
	a {
		.btn {
			opacity: 1 !important;
		}
	}
}
