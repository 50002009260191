/* 

-------------------------------------------------------------

MOBILE NAV

*/

.mobile-nav {
	text-transform:uppercase;

	ul {
		list-style:none;
		margin:0px;
		padding:0px;
		font-weight:900;
	}

	.content {
		width:90%;
		margin:auto;

		a {
			color:#ffffff;
			font-size:20px;
			line-height:60px;
			display:block;
			border-bottom:2px solid #333333;
		}

		a:hover {
			color:#ffffff;
		}

		a.btn { 
			font-size:14px;
			border:none; 
		}
	}
}