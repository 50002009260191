/*

-------------------------------------------------------------

HERO

*/

.hero {
	height:920px;
	padding:0px;
	position:relative;
	overflow:hidden;

	@include breakpoint(small) { height:500px; }

	.slider-nav {
		font-weight:bold;
		font-family:$secondary-font-family;
		text-align:right;
		font-size:12px;
		position:absolute;
		right:40px;
		bottom:40px;
		z-index:30;
		color:#fff;

		@include breakpoint(small) { display:none; }

		a {
			color:#ffffff;
			display:inline-block;
		}

		.previous {
			margin-left:20px;
			margin-right:10px;
			cursor: pointer;
		}
		.next{cursor: pointer;}
		span.slide-title {
			span {
				color:$red;
				margin:0px 10px;
			}
		}
	}
	.heroSlider{
		height: 100%;
		width: 100%;
		position: relative;
		.owl-stage-outer{

		}
		.wrapper{
			position: relative;
			height:100%;
			width:100%;
		}
		.active{
			.text {
				@include transform(scale(1));

			}

		}

	}
	.playvideo img{
		display: inline-block;
	}
	.owl-carousel .owl-stage-outer,.owl-carousel .owl-stage,.owl-carousel .owl-item {
		height: 100%;
	}
	.slideBG{
		height:100%;
		width:100%;
		position:absolute;
		left:0px;
		top:0px;
	//	background:url(../img/bg-hero.jpg) top center no-repeat;
		background-size:cover;
		background-position: center;
		&::before{
			content: "";
			position: absolute;
			top:0;
			left: 0;
			height: 100%;
			width: 100%;
			background: rgba(0, 0, 0, 0.27);
		}
	}
	.slider {
		height:100%;
		width:100%;
		position:relative;
	}

	.slide {
		height:100%;
		width:100%;
		position:absolute;
		left:0px;
		top:0px;
	//	background:url(../img/bg-hero.jpg) top center no-repeat;
		background-size:cover;
		background-position: center;
		@include breakpoint(larger) {  }
	}

	.text {
		@include transition(all 0.75s $easeOutQuint);
		@include transform(scale(0.8));
		position:absolute;
		width:1000px;
		left:0px;
		right:0px;
		top: 550px;
		margin:auto;
		text-align:center;
		color:#ffffff;

		@include breakpoint(large) { width:850px; }

		@include breakpoint(medium) { width:650px; }

		@include breakpoint(small) { width:90%; top: 160px;}

		h2 { margin-bottom:20px; }
	}

	.slide-active {
		.text {
			@include transform(scale(1));

		}
		&::before{
			content: "";
			position: absolute;
			top:0;
			left: 0;
			height: 100%;
			width: 100%;
	    background: rgba(0, 0, 0, 0.27);
		}
	}

	.play-video {
		font-weight:bold;
		font-family:$secondary-font-family;
		text-align:left;
		font-size:12px;
		position:absolute;
		left:40px;
		bottom:40px;
		z-index:30;
		color:#999999;

		@include breakpoint(small) { display:none; }

		img {
			width:20px;
			height:20px;
			vertical-align:middle;
		}

		a {
			color:#999999;
			display:inline-block;
		}

		span.sep {
			color:$red;
			margin:0px 10px;
		}
	}

	.scroll-down {
		position:absolute;
		left:0px;
		bottom:40px;
		z-index:30;

		@include breakpoint(small) {
			width:100%;
			text-align:center;
		}

		img {
			@include transform(rotate(90deg));
			width:20px;
			height:20px;
			vertical-align:middle;
		}
	}

	.header-waypoint {
		position:absolute;
		left:0px;
		top:85px;
	}
}

.hero-interior {
    height:750px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

	@include breakpoint(small) {
		font-size:12px;
		line-height:22px;
		height: 600px;
		.slide .container{
			    padding-top: 120px;
		}
		.scroll-down{display: none;}
	}

	.container { height:100%; @include clearfix;}

	.slider-nav { right:0px; }

	.text {
		text-align:left;
		right:auto;
		left:0px;
		width:600px;
		top:330px;
		color:#ffffff;
		padding: 20px;
		background: rgba($red-dark,.9);
		box-shadow: 0 0 37px rgba(0, 0, 0, 0.55);

		@include breakpoint(small) {
			width:auto;
			text-align:center;
			color:#ffffff;
			position: relative;
			margin: 120px 0px;
			top: 0px;


		}

		h2 { color:#ffffff; }
	}

	.line {
		background: white;
		@include breakpoint(small) {
			margin:30px auto;

		}
	}
	&.abouthero{
		min-height: 920px;
		@include breakpoint(small) {
			height: 850px;
		}
	}
}

.hero-faq {
	height:600px;
	background:#000000;
	background-position:top center;
	background-size:cover;

	@include breakpoint(small) { height:400px; }
}
