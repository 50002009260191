/*

-------------------------------------------------------------

TYPOGRAPHY

*/

body {
	font-family:$primary-font-family;
	font-weight:$primary-font-weight;
	font-size:$primary-font-size;
	color:$primary-font-color;
	line-height:$primary-line-height;

	@include breakpoint(medium) {
		font-size:14px;
		line-height:24px;
	}
}

h1, h2, h3, h4, h5 {
	font-family:$secondary-font-family;
	font-weight:$secondary-font-weight;
	margin:0px;
	padding:0px;
	text-transform:uppercase;
}

h1 {

}

h2 {
	font-size:48px;
	line-height:52px;

	@include breakpoint(large) {
		font-size:40px;
		line-height:44px;
	}

	@include breakpoint(medium) {
		font-size:35px;
		line-height:39px;
	}
}

h3 {
	font-size:35px;
	line-height:40px;

	@include breakpoint(large) {
		font-size:30px;
		line-height:35px;
	}

	@include breakpoint(medium) {
		font-size:25px;
		line-height:30px;
	}
}

h4 {
	font-size:30px;
	line-height:38px;

	@include breakpoint(large) {
		font-size:25px;
		line-height:32px;
	}

	@include breakpoint(medium) {
		font-size:20px;
		line-height:27px;
	}
}
h5{
 font-size: 16px;
}
a {
	text-decoration:none;
}

.line {
	height:2px;
	background:$red;
	width:80px;
	margin:40px 0px;
}

a img { border:none; }
