/*

-------------------------------------------------------------

SHOW

*/


.show-event {

  @media (max-width: 999px) {
    padding-top: 0;
  }

  .show-header {
    margin-bottom: 30px;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 770px) {
      flex-direction: column;
    }
  }

  .show-sidebar {
    flex: 0 1 calc(1/3 * 100% - 50px);
    max-width: calc(1/3 * 100% - 50px);
    width: calc(1/3 * 100% - 50px);

    @media (max-width: 1250px) {
      flex: 0 1 calc(1/3 * 100% - 20px);
      max-width: calc(1/3 * 100% - 20px);
      width: calc(1/3 * 100% - 20px);
    }
    @media (max-width: 960px) {
      flex: 0 1 calc(1/3 * 100%);
      max-width: calc(1/3 * 100%);
      width: calc(1/3 * 100%);
    }
    @media (max-width: 770px) {
      order: 2;
      flex: 0 1 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  .event-header-mobile {
    display: none;

    @media (max-width: 770px) {
      display: block;
      margin-bottom: 10px;
    }
  }

  .show-excerpt {
    margin-bottom: 50px;

    .show-location {
      color: $red;
      padding-left: 10px;
    }

    .separator {
      height: 1px;
      width: 100%;
      background-color: black;
    }

    .show-content {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px 10px;
    }

    .show-date {
      color: $red;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 770px) {
        flex: 0 1 30%;
        max-width: 30%;
        width: 30%;
      }

      h2 {
        font-size: 60px;

        @media (max-width: 1250px) and (min-width: 961px) {
          font-size: 45px;
        }
        @media (max-width: 960px) and (min-width: 771px) {
          font-size: 35px;
        }
      }

      span {
        display: block;
        font-size: 30px;
        line-height: 1;
        text-transform: uppercase;
        margin-bottom: 5px;

        @media (max-width: 1250px) and (min-width: 961px) {
          font-size: 20px;
        }
        @media (max-width: 960px) and (min-width: 771px) {
          font-size: 16px;
        }
      }
    }

    .show-img {
      flex: 0 1 60%;
      max-width: 60%;
      width: 60%;
      overflow: hidden;
      background-color: $red;
      position: relative;

      &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: 56.25%;
      }

      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
  }

  .current-show {
    flex: 0 1 calc(2/3 * 100% - 50px);
    max-width: calc(2/3 * 100% - 50px);
    width: calc(2/3 * 100% - 50px);
    padding: 0 5% 50px;
    margin-top: 40px;

    @media (max-width: 1250px) {
      flex: 0 1 calc(2/3 * 100% - 20px);
      max-width: calc(2/3 * 100% - 20px);
      width: calc(2/3 * 100% - 20px);
      padding: 0 0 50px 5%;
    }
    // @media (max-width: 960px) {
    //   flex: 0 1 calc(2/3 * 100% - 5px);
    //   max-width: calc(2/3 * 100% - 5px);
    //   width: calc(2/3 * 100% - 5px);
    //   padding: 0 0 50px 5%;
    // }

    @media (max-width: 770px) {
      flex: 0 1 100%;
      max-width: 100%;
      width: 100%;
      padding: 0;
    }

    .video-container {
      margin-bottom: 50px;
      position: relative;

      @media (max-width: 960px) {
        margin-bottom: 20px;
      }

      .play {
        height: 100px;
        width: 100px;

        &:hover {
          height: 120px;
          width: 120px;
          background-size: 85%;
        }
      }
    }

    .show-links {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    .show-link {
      flex: 0 1 calc(50% - 10px);
      max-width: calc(50% - 10px);
      width: calc(50% - 10px);
      background-color: $red;

      .btn {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        text-align: center;
      }
    }

    .show-links-has-site {
      flex-wrap: wrap;

      .show-link {
        flex: 0 1 calc(33% - 5px);
        max-width: calc(33% - 5px);
        width: calc(33% - 5px);

        @media (max-width: 960px) and (min-width: 771px), (max-width: 550px) {
          flex: 0 1 calc(50% - 5px);
          max-width: calc(50% - 5px);
          width: calc(50% - 5px);
        }
      }

      .show-url {
        @media (max-width: 960px) and (min-width: 771px), (max-width: 550px) {
          flex: 0 1 100%;
          max-width: 100%;
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }

  .social{
  	margin-bottom: 40px;

    @media (max-width: 770px) {
      text-align: center;
      width: 100%;
    }
  }

  .newsletter {
		text-align:left;
	}

	.newsletter-txt {
		@extend .borderbox;
		font-family:$secondary-font-family;
		color:#8d8d8d;
		font-size:14px;
		letter-spacing:1px;
		background:#404040;
		border:none;
		height:60px;
		float:left;
		width:70%;
		padding:0px 30px;

		@include breakpoint(small) {
			width:100%;
			float:none;
			margin-bottom:15px;
		}
	}

	.newsletter-btn {
		@extend .borderbox;
		@include transition(all 0.2s);
		font-family:$secondary-font-family;
		color:#ffffff;
		font-size:14px;
		background:$red;
		border:none;
		height:60px;
		float:right;
		width:28%;
		cursor:pointer;

		@include breakpoint(small) {
			width:100%;
			float:none;
		}
	}

	.newsletter-btn:hover {
		background:lighten($red, 5%);
	}
}

body.post-type-archive-show.body-modal-open,
body.single-show.body-modal-open {
  position: relative!important;
}

.single-show {
  .active-show {
    background-color: $red;
    padding: 15px;

    .show-location {
      color: white;
    }

    .show-date {
      color: white;
    }
  }

  .current-show {
    margin-top: 0;
  }
}
