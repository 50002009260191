/*

-------------------------------------------------------------

BUTTONS

*/
.faq .contact.intro input.btn{
	background:$red;
	color:white;
	text-transform: uppercase;
}

.btn, button, input[type=submit]{
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
	text-transform: uppercase;
}
input[type=submit]{
	text-align: center;
	padding-left: 16px;
	padding-right: 16px;
}
.btn {
	@include transition(all 0.2s);
	font-family:$secondary-font-family;
	font-size:16px;
	letter-spacing:1px;
	display:inline-block;
	padding-left:20px;
	padding-right:80px;
	line-height:60px;
	background:$red;
	color:#ffffff;
	margin-top:30px;
	position: relative;
	border:none;


	@include breakpoint(small) {
		font-size:14px;
		padding-right:20px;
		border-radius: 0;


	}

	span {
		@include transition(all 0.2s);
		@extend .borderbox;
		background:$red-dark;
		display:inline-block;
		padding-top:20px;
		height:60px;
		width:60px;
		vertical-align:top;
		margin-left:20px;
		text-align:center;
		position: absolute;
		right:0px;
		top:0;

		@include breakpoint(small) { display:none; }

		img {
			width:20px;
			height:20px;
			vertical-align:top;
		}
	}
}

.btn:hover {
	background:lighten($red, 4%);

	span { background:lighten($red-dark, 4%); }
}

.btn-go {
	span { @include transform(rotate(-90deg)); }
}

.btn-gray {
	background:#393838;

	span { background:#1c1c1c; }
}

.btn-gray:hover {
	background:lighten(#393838, 4%);

	span { background:lighten(#1c1c1c, 4%); }
}
