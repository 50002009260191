/*

-------------------------------------------------------------

SECTIONS

*/

section {
	padding:120px 0px;
	position: relative;
	@include clearfix;
	@include breakpoint(small) { padding:90px 0px; }
}

section.white { background:#ffffff; }

section.lightgray { background:#f4f4f4; }

section.gray { background:#323232; }
