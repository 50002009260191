/*

-------------------------------------------------------------

ABOUT

*/
.intro.contact{
	margin-top:150px;
	@include breakpoint(small) { margin-top:40px; margin-bottom:0px; }
}
.about {
	font-size:14px;

	@include breakpoint(small) { font-size:12px; }

	.callout {
		@include clearfix;
		position:relative;

		@include breakpoint(small) { text-align:center; }

		.image {
			width:530px;
            &.top{margin-bottom: 100px;@include breakpoint(small) {margin-bottom:20px;}}
            &.truck{
                width: 250px;
                margin:269px 0 0 0;// -413px -117px;
                position:absolute;
                right:50px;
//            /   z-index: -1;
                @include breakpoint(small) {
				width:100%;
				margin: 0 0 30px;
			}}
             &.beams{ margin-top:-198px;
                @include breakpoint(small) {
				width:100%;
				margin-top: 0;
			}}
            &.wider{ width:580px;
                @include breakpoint(small) {
				width:100%;

			}}
			@include breakpoint(large) { width:430px; }

			@include breakpoint(small) {
				width:100%;
				margin-bottom:30px;
			}
		}

		.negative-bottom {
			margin-top:70px;
			margin-bottom:-300px;
			z-index:0;
			position:relative;
		}

		.text {
			@extend .borderbox;
			padding-top:30px;
			width:270px;

			@include breakpoint(medium) {
				margin-top:20px;
				margin-bottom:60px;
			}

			@include breakpoint(small) { width:100%; }
		}

		.padding-top {
			padding-top:160px;

			@include breakpoint(medium) { padding-top:40px; }
		}
	}

	.callout-left {
		.image {
			float:left;

			@include breakpoint(small) { float:none; }
		}

		.text {
			float:right;
			text-align:left;
			margin-right:200px;

			@include breakpoint(large) { margin-right:100px; }

			@include breakpoint(small) {
				margin:0px;
				text-align:center;
				float:none;
			}
		}

		.line {
			width:150px;
			margin-left:-100px;

			@include breakpoint(small) { margin:30px auto; }
		}
	}

	.callout-right {
		.image {
			float:right;
		}

		.text {
			float:left;
			text-align:right;
			margin-left:200px;

			@include breakpoint(large) { margin-left:100px; }

			@include breakpoint(small) {
				margin:0px;
				text-align:center;
				float:none;
			}
		}

		.line {
			float:right;
			width:150px;
			margin-right:-100px;

			@include breakpoint(small) { margin:30px auto; float:none; }
		}
	}

	.callout-center {
		text-align:center;
		padding:70px 120px;

		@include breakpoint(small) { padding:35px 0px; }

		.line { margin:30px auto; }
	}

	.callout-section {
		background-position:top center;
		background-size:cover;
		height:580px;
		padding:0px;

		@include breakpoint(small) { text-align:center; }

		.scroll-down {
			position:absolute;
			left:0px;
			bottom:40px;
			z-index:30;

			@include breakpoint(small) {
				width:100%;
				text-align:center;
			}

			img {
				@include transform(rotate(90deg));
				width:20px;
				height:20px;
				vertical-align:middle;
			}
		}

		.notes {
			position:absolute;
			right:0px;
			bottom:40px;
			text-align:right;
			color:#999999;
			font-size:13px;

			@include breakpoint(small) { display:none; }
		}

		.container { height:100%; }

		.text {
			@extend .borderbox;
			padding:65px 60px;
			background:#302f2f;
			width:530px;
			height:320px;
			position:absolute;
			left:0px;
			top:0px;
			bottom:0px;
			margin:auto;
			color:#989898;

			@include breakpoint(small) { padding:40px 20px; width:80%; right:0px; }
		}

		h3 { color:#ffffff; }
	}

	.graybox {
		z-index:1;
		position:relative;
		padding-top:60px;
		background:#302f2f;
		margin:80px;
		margin-bottom:0px;
		color:#989898;

		@include breakpoint(small) {
			margin:0px;
			padding:60px 20px;
			margin:60px 0px 0px 0px;
		}

		.callout-left {
			margin-bottom:50px;

			.image {
				margin-left:-80px;
				margin-top:-140px;

				@include breakpoint(small) { margin:0px; }
			}

			.text {
				margin-right:120px;

				@include breakpoint(large) { margin-right:70px; }

				@include breakpoint(small) { margin:0px; }
			}
		}

		.callout-right {
			.image {
				margin-right:-80px;

				@include breakpoint(small) {
					margin:0px;
					margin-bottom:50px;
				}
			}
			.text {
				margin-left:120px;

				@include breakpoint(large) { margin-left:70px; }

				@include breakpoint(small) { margin:0px; }
			}
		}

		.callout-end {
			position:relative;
			text-align:right;
			margin-bottom:-80px;
			z-index:10;
			width:83.6538%;
			float:right;

			@include breakpoint(small) { display:none; }
		}

		h3 { color:#ffffff; }
	}

	.play-video {
		position:absolute;
		top:0px;
		bottom:0px;
		left:0px;
		right:0px;
		margin:auto;
		z-index:10;
		height:120px;
		width:120px;

		@include breakpoint(small) {
			top:auto;
			bottom:auto;
			position:relative;
			margin:auto;
			margin-bottom:30px;
		}

		a {
			@include transition(all 0.2s);
			opacity:0.7;
		}

		a:hover {
			opacity:1;
		}
	}

	.line {
		@include breakpoint(small) { margin:30px auto; }
	}

	h3 {
		font-size:30px;

		@include breakpoint(small) { font-size:20px; }
	}

	img { margin-bottom:-8px; }
}
.littleguy{padding-top:80px;@include breakpoint(small) { padding-top:10px}}
.negmargin{
	margin-bottom: -50px;
	@include breakpoint(small) { margin-bottom: 20px;}
}
.paddingbottomtext{
	@include breakpoint(small) { padding-bottom: 40px;}
}
