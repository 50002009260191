/*

-------------------------------------------------------------

FAQ

*/

input{
    width:100%;
    font-family: "Quicksand", sans-serif;
    color: #8d8d8d;
    font-size: 14px;
    letter-spacing: 1px;
    background: #404040;
    border: none;
    height: 60px;
    //float: left;
   // width: 70%;
    padding: 0px 30px;
    margin-bottom:30px;
}
textarea{
    width: 100%;
    font-family: "Quicksand", sans-serif;
    color: #8d8d8d;
    font-size: 14px;
    letter-spacing: 1px;
    background: #404040;
    border: none;
    padding: 20px 30px;
    height: 100px;
}

.faq {
    .contact.intro{
        height: auto;
        input{
            width:100%;
            font-family: "Quicksand", sans-serif;
            color: #8d8d8d;
            font-size: 14px;
            letter-spacing: 1px;
            background: #404040;
            border: none;
            height: 60px;
            //float: left;
           // width: 70%;
            padding: 0px 30px;
            margin-bottom:30px;
        }
        textarea{
            width: 100%;
            font-family: "Quicksand", sans-serif;
            color: #8d8d8d;
            font-size: 14px;
            letter-spacing: 1px;
            background: #404040;
            border: none;
            padding: 20px 30px;
            height: 100px;
        }
    }
    .contactInfo{
        @include clearfix;

        .col{
            width:50%;
            float: left;
            position: relative;
            @include clearfix;
            text-align: left;
            padding: 0 50px;

            h4{
                font-size: 24px;
                color:white;

            }
            @include breakpoint(small) { width:100%; padding:0; }
        }

    }

	.questions {
		width:980px;

		@include breakpoint(large) { width:780px; }

		@include breakpoint(medium) { width:580px; }

		@include breakpoint(small) { width:90%; }
	}

	.intro {
		@extend .borderbox;
		min-height:300px;
		background:#302f2f;
		margin-top:-270px;
		margin-bottom:100px;
		padding:50px;
		color:#989898;

		@include breakpoint(small) {
			height:auto;
			padding-bottom:80px;
      padding: 50px 20px;
      margin-bottom: 0;

		}

		a { color:#ffffff; }

		.line {
			margin:35px auto;
		}

		h3 { color:#ffffff; }
	}

	ul.question-list {
		list-style:none;
		margin:0px;
		padding:0px;

		li {
			border-bottom:1px solid #eaeaea;
		}

		a.question-open {
			line-height:30px;
			padding:30px 0px;
			display:block;
			font-size:16px;
			font-weight:bold;
			color:#666666;

			path { fill:#cdcdcd; }
		}

		a.question-open:hover {
			color:$red;

			path { fill:$red; }
		}

		span {
			@include transform(rotate(90deg));
			float:right;
			width:15px;
			height:15px;
      pointer-events: none;
		}
	}

	.answer {
		color:#666666;
		border-left:2px solid $red;
		padding-left:60px;
		margin-top:20px;
		margin-bottom:70px;

		@include breakpoint(small) { padding-left:30px; }
	}
}

.pdf {
  margin-bottom: 0 !important;
}

.pdf-hero {
  background-position: center center !important;
}

.pdf-btn {
  padding-left: 20px;
  padding-right: 20px;
}
