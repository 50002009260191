.th-hero {
  @include screen-below(700px) {
    height: 550px !important;
  }
}

.th-text {
  @include transition(all 0.75s $easeOutQuint);
  @include transform(scale(0.8));
  position: absolute;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.th-play-btn {
  position: absolute;
  display: none;
  top: 210px;
  right: 40px;
  background: url('../img/tiny_homes_vid_thumb.jpg'), rgba(0, 0, 0, .70);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  width: 375px;
  height: 150px;
  a {
    color: white;
    position: absolute;
    top: 50%;
    left: 25px;
    text-transform: uppercase;
    transform: translateY(-50%);
    span {
      position: relative;
      &:before {
        position: relative;
        content: '';
        display: inline-block;
        background-image: url('../img/th-play-btn.svg');
        top: 0;
        left: 0;
        width: 12px;
        height: 13px;
        margin-right: 18px;
      }
    }
  }
  @include screen-above(1000px) {
    display: block;
  }
}

.th-play-circle {
  width: 160px;
  height: 160px;
  background: url('../img/tiny_homes_vid_thumb.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: block;
  margin: 25px auto 0;
  position: relative;
  border-radius: 50%;
  a {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  @include screen-below(500px) {
    width: 100px;
    height: 100px;
  }
  @include screen-above(1000px) {
    display: none;
  }
}

.th-play-svg {
  width: 33px;
  height: 35px;
  .st0 {
    fill: #FFFFFF;
  }
}

.th-btn {
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  bottom: 50px;
  padding: 0 40px;
  text-align: center;
  margin: 0 auto;
  max-width: 350px;
  @include screen-below(500px) {
    font-size: 12px;
  }
  @include screen-above(200px) {
    width: 150px;
  }
  @include screen-above(300px) {
    width: 250px;
  }
  @include screen-above(400px) {
    width: 350px;
  }
  @include screen-above(1000px) {
    bottom: 0;
  }
}

.th-flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-flex-wrap: wrap-reverse;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @include screen-above(1200px) {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.th-info {
  position: relative;
  width: 100%;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @extend .th-flex;
  &:before {
    @include screen-above(1800px) {
      position: absolute;
      content: '';
      height: 100%;
      left: 0;
      top: 0;
      background: white;
      width: calc((50% - 900px) / 2);
    }
  }
  &:after {
    @include screen-above(1800px) {
      position: absolute;
      display: block;
      clear: none;
      content: '';
      height: 100%;
      right: 50%;
      top: 0;
      background: white;
      width: calc((50% - 900px) / 2);
    }
  }
  @include screen-above(1200px) {
    padding: 100px 0;
  }
}

.th-info-left,
.th-info-right,
.th-utilities-left,
.th-utilities-right {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  @include screen-above(1200px) {
    -webkit-flex: 0 1 50%;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    max-width: 50%;
  }
}

.th-info-left {
  text-align: center;
  color: white;
  padding: 280px 0;
  @include screen-above(1200px) {
    padding: 0;
  }
}

.th-info-design {
  @include screen-below(1200px) {
    background: url('../img/tiny-cabin.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.th-info-right-circle {
  position: relative;
  margin: 0 auto;
  background: white;
  padding: 80px 0;
  @include screen-above(1200px) {
    @include aspect-ratio(1, 1);
    padding: 0;
    background-size: 534px 534px;
    border-radius: 50%;
    max-width: 534px;
  }
}

.th-info-right-content {
  padding: 0 50px;
  h3 {
    text-transform: none;
  }
  @include screen-above(1200px) {
    position: absolute;
    padding: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 360px;
    margin: 0 auto;
  }
}

.circle-background {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 50%;
  margin: 0 auto;
  width: 50%;
  max-width: 900px;
  height: 100%;
  overflow: hidden;
  .st0 {
    fill: #FFFFFF;
  }
  @include screen-above(1200px) {
    display: block;
  }
}

.th-foundation {
  position: relative;
  padding: 120px 0;
  background-color: #fafafa;
  overflow: hidden;
  z-index: 1;
  @include screen-above(1281px) {
    padding: 180px 0;
  }
}

.th-foundation-container {
  max-width: 1440px;
  padding: 0 50px;
  margin:0 auto;
  overflow: hidden;
  p {
    margin-bottom: 180px;
    @include screen-above(473px) {
      margin-bottom: 205px;
    }
    @include screen-above(530px) {
      margin-bottom: 238px;
    }
    @include screen-above(630px) {
      margin-bottom: 226px;
      max-width: 334px;
    }
    @include screen-above(725px) {
      margin-bottom: 257px;
    }
    @include screen-above(850px) {
      margin-bottom: 296px;
    }
    @include screen-above(961px) {
      margin-bottom: 345px;
    }
    @include screen-above(1100px) {
      margin-bottom: 380px;
    }
    @include screen-above(1281px) {
      margin-bottom: 464px;
    }
  }
  h3,
  h4 {
    text-transform: none;
  }
  h3 {
    font-size: 28px;
  }
  h4 {
    font-size: 14px;
    @include screen-above(630px) {
      font-size: 20px;
    }
  }
  @include screen-below(600px) {
    padding: 0 25px;
  }
}

.th-crawlspace {
  position: relative;
  margin-bottom: 5px;
  @include screen-above(630px) {
    margin-bottom: 16px;
  }
  @include screen-above(725px) {
    margin-bottom: 24px;
  }
  @include screen-above(850px) {
    margin-bottom: 32px;
  }
  @include screen-above(961px) {
    margin-bottom: 40px;
  }
  @include screen-above(1100px) {
    margin-bottom: 54px;
  }
  @include screen-above(1281px) {
    margin-bottom: 80px;
  }
}

.th-crawlspace-line {
  position: absolute;
  top: 20px;
  left: 144px;
  width: 277px;
  height: 1px;
  pointer-events: none;
  @include screen-below(530px) {
    display: none;
  }
}

.th-basement {
  position: relative;
  margin-bottom: 75px;
  @include screen-above(650px) {
    margin-bottom: 100px;
  }
  @include screen-above(1281px) {
    margin-bottom: 150px;
  }
}

.th-foundation-image {
  position: absolute;
  background: url('../img/th-foundation-image.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  top: 275px;
  left: 245px;
  right: 0;
  margin: 0 auto;
  width: 132px;
  height: 266px;
  @include screen-above(376px) {
    left: 222px;
  }
  @include screen-above(410px) {
    left: 211px;
  }
  @include screen-above(500px) {
    left: 231px;
  }
  @include screen-above(530px) {
    top: 269px;
    left: 260px;
    width: 165px;
    height: 332px;
  }
  @include screen-above(600px) {
    top: 269px;
    left: 258px;
    width: 165px;
    height: 332px;
  }
  @include screen-above(630px) {
    top: 275px;
    left: 278px;
    width: 148px;
    height: 295px;
  }
  @include screen-above(725px) {
    top: 267px;
    left: 309px;
    width: 184px;
    height: 369px;
  }
  @include screen-above(850px) {
    top: 261px;
    left: 333px;
    width: 216px;
    height: 434px;
  }
  @include screen-above(961px) {
    top: 252px;
    left: 379px;
    width: 271px;
    height: 542px;
  }
  @include screen-above(1100px) {
    top: 201px;
    left: 337px;
    width: 339px;
    height: 678px;
  }
  @include screen-above(1281px) {
    top: 230px;
    left: 424px;
    width: 424px;
    height: 848px;
  }
}

.th-foundation-outline {
  position: absolute;
  top: 298px;
  left: 100px;
  right: 0;
  margin: 0 auto;
  width: 312px;
  height: 202px;
  z-index: -1;
  @include screen-between(530px, 600px) {
    width: 391px;
    height: 253px;
  }
  @include screen-between(600px, 630px) {
    width: 391px;
    height: 253px;
  }
  @include screen-between(630px, 725px) {
    width: 434px;
    height: 281px;
  }
  @include screen-between(725px, 850px) {
    width: 510px;
    height: 331px;
  }
  @include screen-between(850px, 961px) {
    width: 601px;
    height: 390px;
  }
  @include screen-between(961px, 1100px) {
    width: 751px;
    height: 487px;
  }
  @include screen-between(1100px, 1281px) {
    top: 258px;
    width: 939px;
    height: 609px;
  }
  @include screen-above(500px) {
    left: 120px;
  }
  @include screen-above(630px) {
    left: 160px;
  }
  @include screen-above(1100px) {
    left: 65px;
  }
  @include screen-above(1281px) {
    top: 258px;
    left: 90px;
    width: 1174px;
    height: 848px;
  }
}

.th-utilities {
  @extend .th-flex;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  padding: 0;
}

.th-utilities-left {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include screen-below(1200px) {
    padding: 300px 0;
  }
}

.th-utilities-right {
  color: white;
  background-color: #414042;
  padding: 60px 0;
  text-align: center;
  h4 {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 52px;
    @include screen-above(960px) {
      max-width: 450px;
    }
  }
  a {
    color: white;
    text-decoration: underline;
  }
  @include screen-above(500px) {
    padding: 180px 0;
  }
  @include screen-above(1200px) {
    padding: 262px 0;
  }
}

.th-info-utilities {
  @include screen-below(1200px) {
    background: url('../img/th-sustainable.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.th-warranty {
  background-color: #fafafa;
}

.th-warranty-flex-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 50px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.warranty-item {
  position: relative;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  @include screen-above(900px) {
    -webkit-flex: 0 1 33.333%;
    -ms-flex: 0 1 33.333%;
    flex: 0 1 33.333%;
    max-width: 33.333%;
    &:first-child {
      border-right: 1px solid rgba(0, 0, 0, .10);
    }
    &:last-child {
      border-left: 1px solid rgba(0, 0, 0, .10);
    }
  }
}

.warranty-item-content-left {
  @include screen-below(900px) {
    padding: 0 0 60px;
  }
  @include screen-between(900px, 1000px) {
    padding: 60px 20px 120px 0;
  }
  @include screen-between(1000px, 1100px) {
    padding: 60px 40px 0 0;
  }
  @include screen-above(1100px) {
    padding: 120px 80px 0 0;
  }
}

.warranty-item-content-right {
  @include screen-below(900px) {
    padding: 60px 0 0;
  }
  @include screen-between(900px, 1000px) {
    padding: 60px 0 0 20px;
  }
  @include screen-between(1000px, 1100px) {
    padding: 60px 0 0 40px;
  }
  @include screen-above(1100px) {
    padding: 120px 0 0 80px;
  }
}

.warranty-circle-wrapper {
  position: relative;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  text-align: center;
}

.warranty-circle {
  display: block;
  margin: 0 auto;
  max-width: 398px;
  max-height: 398px;
  @include screen-between(300px, 500px) {
    min-width: 200px;
    min-height: 200px;
  }
  @include screen-between(500px, 860px) {
    min-width: 398px;
    min-height: 398px;
  }
}

.th-gallery {
  padding: 0;
  margin: 0 -10px;
  background: white;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  @include screen-above(768px) {
    padding: 100px 0 80px;
  }
}

.gallery-item {
  position: relative;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  margin-bottom: 20px;
  @include aspect-ratio(16, 9);
  @include screen-between(768px, 1200px) {
    -webkit-flex: 0 1 calc(50% - 20px);
    -ms-flex: 0 1 calc(50% - 20px);
    flex: 0 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin: 0 10px 20px;
  }
  @include screen-above(1200px) {
    -webkit-flex: 0 1 calc(33.333% - 20px);
    -ms-flex: 0 1 calc(33.333% - 20px);
    flex: 0 1 calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
    margin: 0 10px 20px;
  }
}

.gallery-image-1 {
  order: 1;
}

.download {
  color: white;
  background-color: #414042;
  order: 2;
  @include screen-below(768px) {
    order: 5;
  }
}

.gallery-image-2 {
  order: 2;
  @include screen-above(768px) {
    order: 3;
  }
}

.gallery-image-3 {
  order: 3;
  @include screen-above(768px) {
    order: 4;
  }
}

.gallery-image-4 {
  order: 5;
  @include screen-below(768px) {
    order: 4;
  }
}

.download-content {
  position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: center;
	text-align: center;
}

.content-download {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.th-download {
  color: white;
  background: #c5242d;
  padding: 15px 54px;
  margin-top: 30px;
}

.th-map {
  position: relative;
  width: 100%;
  padding: 200px 0;
  overflow: hidden;
  background-color: #f9f9f8;
  z-index: 1;
  @include screen-above(1024px) {
    padding: 120px 0 400px;
  }
}

.th-map-svg {
  position: absolute;
  margin: 0 auto;
  bottom: -25px;
  right: 0;
  left: -350px;
  width: 608px;
  height: 375px;
  z-index: -1;
  .st0 {
    fill: none;
    stroke: #414042;
    stroke-width: 0.7728;
    stroke-miterlimit: 10;
  }
  @include screen-between(440px, 540px) {
    left: -400px;
  }
  @include screen-between(540px, 685px) {
    left: -500px;
    width: 760px;
    height: 468px;
    bottom: -75px;
  }
  @include screen-between(685px, 1024px) {
    width: 1085px;
    height: 668px;
    top: 60px;
    left: -600px;
  }
  @include screen-between(1024px, 1440px) {
    width: 878px;
    height: 540px;
    bottom: -150px;
    left: 0;
  }
  @include screen-above(1440px) {
    width: 1098px;
    height: 676px;
    bottom: -275px;
    left: 0;
  }
}

.th-map-content {
  position: relative;
  max-width: 888px;
  margin: 0 auto;
  padding: 0 50px;
  h4 {
    position: relative;
    text-transform: none;
    text-align: right;
    @include screen-below(750px) {
      max-width: 175px;
      margin: 0 0 0 auto;
    }
    @include screen-between(750px, 1024px) {
      line-height: 1.2;
      max-width: 320px;
      margin: 0 0 0 auto;
    }
    @include screen-above(1024px) {
      max-width: 420px;
      text-align: left;
    }
  }
}

.th-map-line-left {
  position: absolute;
  bottom: 0;
  left: -100px;
  width: 175px;
  height: 7px;
  @include screen-above(600px) {
    width: 241px;
  }
  @include screen-between(600px, 960px) {
    left: -200px;
  }
  @include screen-between(960px, 1024px) {
    left: -241px;
  }
  @include screen-above(1024px) {
    display: none;
  }
}

.th-map-line-right {
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 501px;
  height: 7px;
  @include screen-below(1024px) {
    display: none;
  }
  @include screen-above(1440px) {
    bottom: -45px;
  }
}

.th-timeline-flex {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.th-timeline {
	position: relative;
  color: white;
  background: #414042;
	padding: 120px 50px;
	overflow: hidden;
	z-index: 1;
	h3 {
		max-width: 585px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		text-transform: uppercase;
	}
	@include screen-above(1281px) {
		padding: 120px 100px;
	}
}

.th-timeline-container {
  @include screen-above(1801px) {
    position: relative;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
  }
}

.th-timeline-image {
	position: absolute;
	top: 300px;
	left: 0;
	background: url('../img/th-timeline-image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
	z-index: -1;
	@include screen-between(1080px, 1281px) {
		width: 534px;
		height: 480px;
	}
	@include screen-between(1281px, 1440px) {
		width: 623px;
		height: 560px;
	}
	@include screen-between(1440px, 1660px) {
		width: 712px;
		height: 640px;
	}
	@include screen-above(1660px) {
		width: 890px;
		height: 800px;
	}
  @include screen-above(1801px) {
    top: 200px;
  }
}

.th-top-text {
	margin-bottom: 181px;
  @include screen-below(500px) {
    margin-bottom: 120px;
  }
	@include screen-above(1440px) {
		margin-bottom: 362px;
	}
}

.th-bottom-text {
	margin-top: 120px;
}

[class^="th-timeline-wrap"] {
	width: 100%;
	max-width: 1440px;
	margin: 0 auto 80px auto;
	@include clearfix;
}

.th-timeline-wrap-2 {
	@include screen-above(1281px) {
		padding-right: 100px;
	}
}

.th-timeline-wrap-3 {
	@include screen-above(1281px) {
		padding-right: 200px;
	}
}

.th-timeline-wrap-4 {
	margin-bottom: 0;
	@include screen-above(1281px) {
		padding-right: 300px;
	}
}

.th-timeline-row {
	@extend .th-timeline-flex;
  @include screen-above(375px) {
    min-width: 300px;
  }
	@include screen-above(1080px) {
		float: right;
		max-width: 448px;
		min-width: 448px;
	}
}

.th-timeline-number {
	position: relative;
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	-webkit-flex: 0 1 50%;
	-ms-flex: 0 1 50%;
	flex: 0 1 50%;
	max-width: 50%;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	align-self: auto;
	svg {
    width: 72px;
		height: 72px;
	}
	&:before {
		position: absolute;
		content: '';
		left: 0;
		bottom: -14px;
		background: #242325;
		width: 1000%;
		height: 10px;
		z-index: -1;
	}
}

.th-number-1 {
	width: 56px;
}

.th-number-2,
.th-number-3 {
	width: 63px;
}

.th-number-4 {
	width: 66px;
}

.th-timeline-content {
	-webkit-order: 0;
	-ms-flex-order: 0;
	order: 0;
	-webkit-flex: 0 1 50%;
	-ms-flex: 0 1 50%;
	flex: 0 1 50%;
	max-width: 50%;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	align-self: auto;
	float: right;
	h4 {
    text-transform: none;
		margin-top: 0;
	}
}

.th-time {
	position: relative;
	&:before {
		position: absolute;
		content: '';
		width: 32px;
		height: 3px;
		top: 7px;
		left: -42px;
		background: #c5242d;
	}
}

.th-bottom-cta {
  padding: 50px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  @include screen-below(600px) {
    padding: 50px 25px;
  }
}

.th-bottom-cta-item {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  margin-bottom: 20px;
  @include aspect-ratio(16, 9);
  @include screen-above(1200px) {
    -webkit-flex: 0 1 calc(50% - 20px);
    -ms-flex: 0 1 calc(50% - 20px);
    flex: 0 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin: 0 10px 20px;
  }
}

.th-bottom-cta-content {
  position: absolute;
  bottom: -100px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h4 {
    max-width: 520px;
    margin: 0 auto;
    a {
      color: white;
      text-decoration: underline;
    }
    @include screen-below(700px) {
      font-size: 18px;
      max-width: 350px;
    }
  }
  @include screen-below(600px) {
    bottom: -50px;
  }
  @include screen-below(375px) {
    bottom: 0;
  }
}

.th-sidebar-wrap {
  position: relative;
  width: 100%;
}

.th-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 100%;
  background: black;
  z-index: 9;
  @include screen-below(1281px) {
    display: none;
  }
}

.th-sidebar-content {
  position: absolute;
  top: 1295px;
  right: 0;
  color: white;
  margin: -116px;
  padding: 0;
  li {
    position: relative;
    display: inline-block;
    a {
      color: white;
      font-size: 10px;
      padding: 0 25px;
      text-transform: uppercase;
      span {
        color: #dddddd;
      }
      @include transition(all 300ms ease-in-out);
      &:hover {
        color: rgba(255, 255, 255, 0.5);
      }
    }
    &:before {
      position: absolute;
      content: '';
      bottom: -25px;
      left: 0;
      width: 1px;
      height: 250%;
      background: #dddddd;
    }
  }
  z-index: 10;
  @include transform(rotate(-90deg));
  @include screen-below(1281px) {
    display: none;
  }
}
