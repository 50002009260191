//
// MANIPULATION
//

@mixin box-shadow($str) {
  -webkit-box-shadow: $str;
     -moz-box-shadow: $str;
      -ms-box-shadow: $str;
          box-shadow: $str;
}

@mixin border-radius($str) {
  -webkit-border-radius: $str;
     -moz-border-radius: $str;
      -ms-border-radius: $str;
          border-radius: $str;
}

@mixin scale($size) {
  -webkit-transform: scale($size);
     -moz-transform: scale($size);
      -ms-transform: scale($size);
          transform: scale($size);
}

@mixin transform($str) {
  -webkit-transform: $str;
     -moz-transform: $str;
      -ms-transform: $str;
          transform: $str;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

//
// TRANSITIONS
//

@mixin keyframes($animation-name) {
  @-webkit-keyframes $animation-name {
    @content;
  }
  @-moz-keyframes $animation-name {
    @content;
  }
  @-ms-keyframes $animation-name {
    @content;
  }
  @-o-keyframes $animation-name {
    @content;
  }
  @keyframes $animation-name {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -ms-animation-delay: $delay;
  -o-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transition-delay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -ms-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}


//
// BREAK POINTS
//

@mixin breakpoint($point) {
  @if $point == larger {
    @media (min-width: 1780px) { @content; }
  }
  @else if $point == large {
    @media (max-width: 1250px) { @content; }
  }
  @else if $point == medium {
    @media (max-width: 960px) { @content; }
  }
  @else if $point == small {
    @media (max-width: 860px)  { @content; }
  }
}

@mixin vertical-breakpoint($point) {
  @if $point == xlarge {
    @media (max-height:1000px) { @content; }
  }
  @else if $point == large {
    @media (max-height:900px) { @content; }
  }
  @else if $point == medium {
    @media (max-height:800px) { @content; }
  }
  @else if $point == small {
    @media (max-height:400px)  { @content; }
  }
}

@mixin screen-above($screen-size) {
  @media screen and (min-width: $screen-size) {
    @content;
  }
}

@mixin screen-below($screen-size) {
  @media screen and (max-width: ($screen-size - 1px)) {
    @content;
  }
}

@mixin screen-between($min-screen-size, $max-screen-size) {
  @media screen and (min-width: $min-screen-size) and (max-width: ($max-screen-size - 1px)) {
    @content;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top:40px;
    color:white;
    text-align: center;
    h2{margin-bottom: 20px;font-size: 40px;}
    a{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      .btn{
        opacity: 0;
        width: 180px;
      //  padding-left: 36px;
        height: 60px;
        margin: auto;
        position: absolute;
        text-align: center;
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        left: 0;
        bottom:0;
        margin:auto;

      }
      &:hover{
        .btn{opacity: 1;}
      }
    }
    .line{
      display: inline-block;
      margin:0 auto;
      position: absolute;
      left: 0;
      right:0;
      //margin-top:20px;
    }

  }
}
