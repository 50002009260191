/*

-------------------------------------------------------------

GLOBAL

*/
*,*:before,*:after{box-sizing: border-box;}
body {
//	background:#212121;
	padding:0px;
	margin:0px;
	@include screen-below(768px) {
		-webkit-overflow-scrolling: touch;
	}
}

#viewport {
	overflow:hidden;
	width:100%;
	position:relative;
}

.container {
	margin:auto;
	width:1200px;
	position:relative;

	@include breakpoint(large) { width:900px; }

	@include breakpoint(medium) { width:700px; }

	@include breakpoint(small) { width:90%; }
}


/* general */

img { max-width:100%; }

a, a img {
    color:$red;

	/*-webkit-transform: rotate(0);
	-moz-transform: rotate(0);
	transform: rotate(0);
	transform: translate3d(0px,0px,0px);
	-webkit-backface-visibility:hidden;
	backface-visibility:hidden;*/
}
.gallery-wrapper{
    overflow: hidden;

   // perspective: 1000px;
}
.owl-house-gallery{
    max-width: 700px;
    margin:auto;
    .owl-item{
        //opacity: .25;
        margin-top: 100px;
        margin-bottom:100px;
        background:black;
        img{opacity: .5;}
        &.active{
            opacity: 1;
            z-index: 99;
            img{
                opacity: 1;
                transform: scale(1.125);
                box-shadow: 0px 0px 55px 20px rgba(black, .69);
            }
        }
    }
    .owl-stage-outer{
        overflow: visible;
    }

}
.borderbox {
	box-sizing:border-box;
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
}

.clear { clear:both; }

.hide-ipad {
	@include breakpoint(medium) { display:none; }
}

.hide-mobile {
	@include breakpoint(small) { display:none; }
}

.icon{
    height:26px;
    fill:#414141;
    margin-right:10px;
    margin-bottom:-1px;
    vertical-align: baseline;
    &.icon-light{
        fill:#fff;
    }
}
.postswrap{
    background:white;
    width:100%;
    padding-top:150px;//85px;
		@include breakpoint(small){
			padding-top:65px;
		}
    .header-waypoint{
        position: absolute;
         top:85px;
    }
}
.postsheader{
    height:550px;
    width:100%;
    //background:$red-dark;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
		@include breakpoint(small){
			height:250px;
		}
}
.postscontainer{
    margin-top:-200px;
    background:#fff;
    padding:110px 200px;
		@include breakpoint(medium){
			padding:75px 50px;
		}
		@include breakpoint(small){
				padding:40px 14px 60px 14px;
				margin-top:0px;


		}

}
.thumb{
	img{
		float: left;
		margin: 7px 34px 24px 0;
	}
	@include breakpoint(small){
		@include clearfix;
	}
}
.blog-post{
	padding:300px 0 120px 0;
	@include breakpoint(small){
			padding:14px 14px 60px 14px;
	}
}
.alignright{
	float:right;
	margin: 20px 0;
	margin-left:50px;
	@include breakpoint(small){
		float: none;
		margin-left:0;
	}
}
.alignleft{
	float:left;
	margin: 20px 0;
	margin-right:50px;
	@include breakpoint(small){
		float: none;
		margin-right:0;
	}
}
