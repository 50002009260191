/*

-------------------------------------------------------------

COLUMNS

*/

.row {
	@include clearfix;

	.column-2 {
        p{margin-top:0;}
        -webkit-columns: 2;// 565px;
        -moz-columns: 2;
        columns: 2;
        -webkit-column-gap: 2em;
        -moz-column-gap: 2em;
        column-gap: 2em;
		//@extend .borderbox;
		//width:565px;
		//float:right;
		//position:relative;

		//@include breakpoint(large) { width:400px; }

//		@include breakpoint(medium) { width:300px; }

		@include breakpoint(small) {
			-webkit-columns: 1;// 565px;
            -moz-columns: 1;
            columns: 1;
            -webkit-column-gap: 2em;
            -moz-column-gap: 2em;
            column-gap: 2em;
		}
	}

	.column-2:first-child {
//		float:left;

	//	@include breakpoint(small) { float:none; }
	}

	.column-4 {
		@extend .borderbox;
		float:left;
		width:25%;

		@include breakpoint(small) { width:50%; }
	}

	.column-4:first-child {
		margin-left:0px;
	}
}
