/*

-------------------------------------------------------------

MAP

*/

.homemap{
    height:900px;
    padding:0;
    position: relative;
    #map{height:100%;
      width: 100%;
      position: relative;
      .gm-style-iw{padding-top:10px;a{font-size:20px;margin:0 10px;}}
    }

    .container{
        z-index: 9;
        position: absolute;
        bottom:80px;
        left: 0;
        right: 0;
        margin:0 auto;
        @include breakpoint(small){
          bottom:0;
        }
    }
    .location{
        z-index: 5;
        width:30%;
        margin:0 2.5%;
        height: 230px;
        background: darkred;
        position:relative;
        float: left;
        background-size:cover;
        background-repeat: no-repeat;
        background-position: center;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.27);
        &:first-of-type{
            margin-left:0;
        }
         &:last-of-type{
            margin-right:0;
        }
        a{
            background:rgba(10,10,10,0.37);
            display: block;
            height: 100%;
            width:100%;
            position: relative;
            transition:.2s;
            .overlaytext{
                right:0px;
                top:0px;
                bottom:0px;
                left:0;
                margin:auto;
                position:absolute;
                text-align:center;
                height:55px;
                font-size:20px;
                line-height: 1.5;
                color:#fff;


            }

            span.btn{
                width:180px;
                left:0px;
                padding-left: 36px;
                right:0px;
                top:0px;
                bottom:0px;
                height:60px;
                margin:auto;
                position:absolute;
                text-align:center;
                opacity: 0;
                span{margin-left:36px;}
            }
            &:hover{
                background:transparent;
                span.btn{
                    opacity: 1;
                }
                .overlaytext{
                    opacity: 0;
                }
            }
        }
        @include breakpoint(small){
          width:100%;
          margin:15px 0;
          &:first-of-type{
              margin-left:0;
              margin-right:0;
          }
           &:last-of-type{
              margin-right:0;
              margin-left:0;
          }
        }
    }
    @include breakpoint(small){
      height: 1220px;
      #map{
        height:900px;
        width: 100%;
        position: relative;
        .gm-style-iw{padding-top:10px;a{font-size:20px;margin:0 10px;}}
      }
    }
}
.inner-map{
  height:800px;
  padding: 0;
  margin: 0;
  position: relative;
  #map{height:100%;width: 100%;position: relative;.gm-style-iw{padding-top:10px;a{font-size:20px;margin:0 10px;}}}
  @include breakpoint(medium) {
		height:600px;
	}
  @include breakpoint(small) {
		height:350px;
	}
}
section.map {
	height:680px;
	padding:0px;
	background:#dfdfdf url(../img/bg-map.jpg) top center no-repeat;

	@include breakpoint(large) {
		height:700px;
	}

	@include breakpoint(small) {
		height:auto;
		padding:100px 0px;
		text-align:center;
	}

	.text {
		@extend .borderbox;
		padding:60px;
		width:550px;
		height:400px;
		background:rgba(50,50,50,0.9);
		position:absolute;
		top:0px;
		bottom:0px;
		left:0px;
		margin:auto;
		color:#989898;

		@include breakpoint(large) {
			width:400px;
			height:520px;
		}

		@include breakpoint(small) {
			width:100%;
			height:auto;
			position:relative;
			padding:40px;
		}

		h3 { color:#ffffff; }

		.line {
			margin:30px 0px;
			width:60px;

			@include breakpoint(small) { margin:30px auto; }
		}
	}

	.radial {
		@extend .borderbox;
		@include border-radius(100%);
		padding:60px;
		background:rgba(0,0,0,0.1);
		height:630px;
		width:630px;
		position:absolute;
		right:0px;
		top:0px;
		bottom:0px;
		margin:auto;

		@include breakpoint(large) {
			@include transform(scale(0.9));
			right:-80px;
		}

		@include breakpoint(medium) { right:-250px; }

		@include breakpoint(small) { display:none; }

		.area {
			color:#ffffff;
			position:absolute;
		}

		.mi {
			right:100px;
			top:230px;
		}

		.mn {
			left:30px;
			top:130px;
		}

		.wi {
			left:100px;
			top:230px;
		}

		.il {
			left:180px;
			top:400px;
		}

		.inner-black {
			@extend .borderbox;
			@include border-radius(100%);
			padding:30px;
			background:rgba(0,0,0,0.1);
			height:100%;
			width:100%;
			position:relative;
		}

		.inner-red {
			@extend .borderbox;
			@include border-radius(100%);
			background:rgba(199,32,46,0.8);
			height:100%;
			width:100%;
		}
	}

	.container { height:100%; }
}
