/*
Theme Name: Project Name
Author: Elegant Seagulls
Author URI: http://elegantseagulls.com
*/

/* Helpers */
@import "helpers/variables";
@import "helpers/mixins";

/* Base */
@import "base/typography";
@import "base/global";

/* Components */
@import "components/sections";
@import "components/buttons";
@import "components/columns";
@import "components/modal";

/* Layout */
@import "layout/header";
@import "layout/navigation";
@import "layout/mobile-navigation";
@import "layout/footer";

/* Sections */
@import "sections/hero";
@import "sections/intro";
@import "sections/highquality";
@import "sections/map";
@import "sections/homes-grid";
@import "sections/homes";
@import "sections/philosophy";
@import "sections/faq";
@import "sections/about";
@import "sections/tour";
@import "sections/download";
@import "sections/featuredlayout";
@import "sections/preorder";
@import "sections/team";
@import "sections/process";
@import "sections/tiny";
@import "sections/show";
