/*

-------------------------------------------------------------

HEADER

*/

header {
	font-family:$secondary-font-family;
	position:absolute;
	z-index:999;
	width:100%;
	height:85px;
	//background:url(../img/bg-blacktrans.png) repeat;
    //background:rgba(50, 50, 50, 0.7);
	font-size:15px;
	letter-spacing:1px;

	@include breakpoint(large) { font-size:12px; }



	.logo {
		position:absolute;
		width:300px;//235px;
		left:40px;
		top:30px;
		a{width: 100%;}
		@include breakpoint(medium) { left:30px; }
	}
	.menu-btn {
		display: none;
		@include transition(all 0.75s $easeOutQuint);
		width:50px;
		height:20px;
		position:absolute;
		right:0;
		top:20px;

		//margin:auto;
		// @include breakpoint(small){
		@include screen-below(1000px){
			display:block;
		}
		a {
			display:block;
			height:20px;
			margin-top:-2px;
		}

		span, span:before, span:after {
			@include transition(all 0.75s $easeOutQuint);
			position:absolute;
			top:10px;
			left:0px;
			display:block;
			height:2px;
			width:30px;
			background:#ffffff;
			content:"";
		}

		span:before { top:-6px; }

		span:after { top:6px; }
	}
	.topnav {
		position:absolute;
		right:40px;
		top:0px;
		//width:400px;
		text-align:right;
		line-height:85px;

		@include breakpoint(medium) { right:30px; }

		a {
			margin-left:30px;

			@include breakpoint(medium) { margin-left:20px; }
		}

		a:hover { color:$red; }
	}

	.navigation{
		padding-left:40px;
		position:absolute;
		left:0px;
		top:85px;
		height:65px;
		width:100%;
		background:$red;
		z-index:100;
		line-height:65px;

		@include breakpoint(medium) { padding-left:30px; }
		// @include breakpoint(small){
		@include screen-below(1000px){
			top:65px;
			visibility:hidden;
			opacity:none;
	    height: initial;
			.start{display: none;}
			&.open{
				position: absolute;
				visibility:visible;
				opacity:1;

			}
		}
	}
    nav{
        background:rgba(50, 50, 50, 0.8);
        height:inherit;
				// @include breakpoint(small){
				@include screen-below(1000px){
					background:none;
					&.open{
							background:$red-dark;
					}
					&.fixed{top:0;}
				}
    }
	nav.fixed {
		position:fixed;
        width:100%;

		top:-85px;
        @include transition(.2s);
        &.unhide{
            top:0px;
        }
	}

	a {
		@include transition(all 0.2s);
		color:#ffffff;
        display: inline-block;
	}

	a.start {
		float:right;
		padding:0px 40px;
		display:inline-block;
		background:$red-dark;

		@include breakpoint(medium) { padding:0px 20px; }
	}

	a.start:hover {
		background:darken($red-dark, 3%);
	}

	a:hover { color:#ffb4ba; }

	ul {
		margin:0px;
		padding:0px;
		list-style:none;

		li {
			float:left;
			position:relative;
			margin-right:30px;

			@include breakpoint(large) { margin-right:20px; }
		}
	}
	// @include breakpoint(small) {
	@include screen-below(1000px){
		@include transition(all 0.75s $easeOutQuint);
		box-sizing: border-box;
		display:block;
		position:fixed;
		left:0px;
		top:0px;
		height:65px;
		width:100%;
		background:$red;
		z-index:200;
		.navigation{
			padding-left: 0;
			padding-right: 0;
			line-height: 55px;
			overflow: auto;
	    position: absolute;
			margin-top:65px;
	    bottom:0;
	    left:0;
	    right:0;
	    top:0;
		}
		ul li{
			display: block;
			float: none;
			margin:0;
			a{
				padding:0 16px;
				display: block;
				background: $red;
				border-top:1px solid $red-dark;
			}
		}
		.logo {
			width:155px;
			left:5%;
			top:25px;
		}
		.topnav{display: none;}
		&.open{
			height: 100%;
		}
	}
}
//
// header.mobile {
// 	@include transition(all 0.75s $easeOutQuint);
// 	@extend .borderbox;
// 	display:block;
// 	visibility:hidden;
// 	opacity:0;
// 	padding-left:40px;
// 	position:fixed;
// 	left:0px;
// 	top:0px;
// 	height:65px;
// 	width:100%;
// 	background:$red;
// 	z-index:200;
//
// 	@include breakpoint(small) {
// 		visibility:visible;
// 		opacity:100;
// 	}
//
// 	.logo {
// 		width:155px;
// 		left:5%;
// 		top:25px;
// 	}
//
// 	.menu-btn {
// 		@include transition(all 0.75s $easeOutQuint);
// 		width:30px;
// 		height:20px;
// 		position:absolute;
// 		right:5%;
// 		top:0px;
// 		bottom:0px;
// 		margin:auto;
//
// 		a {
// 			display:block;
// 			height:20px;
// 			margin-top:-2px;
// 		}
//
// 		span, span:before, span:after {
// 			@include transition(all 0.75s $easeOutQuint);
// 			position:absolute;
// 			top:10px;
// 			left:0px;
// 			display:block;
// 			height:2px;
// 			width:30px;
// 			background:#ffffff;
// 			content:"";
// 		}
//
// 		span:before { top:-6px; }
//
// 		span:after { top:6px; }
// 	}
//
// 	ul {
// 		list-style:none;
// 		width:100%;
//
// 		a {
// 			color:#ffffff;
// 			text-decoration:none;
// 			font-size:30px;
// 			line-height:50px;
// 			display:block;
// 		}
// 	}
// }





		.menu-btn .burger.open{
			span, span:before, span:after { background:#ffffff; }

			span { @include transform(rotate(45deg)); }

			span:before { top:0px; }

			span:after {
				@include transform(rotate(-90deg));
				top:0px;
			}
		}
