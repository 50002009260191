/*

-------------------------------------------------------------

HOMES GRID

*/


// .owl-video-frame{
//   height: 500px;
//   width: 100%;
// }



.concepts{
    padding-bottom: 100px;
    @include breakpoint(small){
      padding-bottom:0;
    }
    .concept{
        width:calc(33.333% - 10px);
        float: left;
        margin: 15px 0 0 0;

        .featured-img{
            width:calc(100% - 0px);
            margin:0 auto;
            background-size:cover;
            position: relative;
            background-color: $primary;
            &:before {
              display: block;
              content: "";
              width: 100%;
              padding-top: 75%;
            }
            &.locked{
              &:before{
                background: rgba($brand, 0.69) url(../lock.png) center no-repeat;

              }
              &:hover{
                &:before{
                  background: rgba($brand, 0.37) url(../lock.png) center no-repeat;
                }
              }
            }
        }
        .btnDL{
          width: 100%;
          margin:0;
          padding-left:20px;
          padding-right:20px;
          text-align: center;
        }
        .btn-grid{
            width:33.333%;
            display: inline-block;
            text-align: center;
            margin:0px;
            float: left;
            padding:10px;
            line-height: 40px;
            cursor: pointer;

            &:nth-of-type(1){
                background:$red-dark;
                &:hover{
                    background:lighten($red-dark, 5%);
                }
            }
             &:nth-of-type(3){
                background:lighten($red, 5%);
                  &:hover{
                    background:lighten($red-dark, 2%);
                }
            }
            //border-right:1px solid #fff;
            //border-top: none;
        }


        &:nth-of-type(3n+2){
            margin: 15px 15px 0 15px;

        }
        @include breakpoint(medium){
            width:calc(50% - 8px);
             &:nth-of-type(3n+2){
                margin: 15px 0;

            }
            &:nth-of-type(1){

                //background-color:$red-dark;
                margin-right:16px;

             }
        }
        @include breakpoint(small){
            width:100%;
             &:nth-of-type(3n+2){
                margin: 15px 0;

            }
            &:nth-of-type(1){

                //background-color:$red-dark;
                margin-right:0;

             }
        }
    }

    @include clearfix;
}






.homes-grid {

	.homes-nav {

		text-transform:uppercase;
		margin-bottom:40px;

		@include breakpoint(medium) { font-size:14px; }

		.tab {
      color:#bdbdbd;
      display: inline-block;
      padding:0 30px;
      border-left:2px solid #bdbdbd;
      @include breakpoint(medium) { padding:0px 10px; }
      &:first-of-type{
        padding-left: 0;
        border-left:none;
      }
    }

		.tab:hover, .tab.active { cursor: pointer;color:#000000; }


	}

	.grid {
    display: none;
		@include clearfix;
		background:#323232;
    &.active{display: block;}
		a {
			@include transition(all 0.2s);
			position:relative;
			display:block;
			width:33.333%;
			float:left;
			opacity:0.5;

			@include breakpoint(medium) { width:50%; opacity: 1;}
      @include breakpoint(small) { }
		}

		a:hover {
			opacity:1;
			span.btn { opacity:1; }
		}

		span.btn {
			@include transition(all 0.2s);
			width:180px;
			left:0px;
			right:0px;
			top:0px;
			bottom:0px;
			height:60px;
			margin:auto;
			position:absolute;
			text-align:center;
			opacity:0;
      @include breakpoint(medium) {width: 100%;span{display: none;}}
			span { float:right; }
		}
    .img-grid{
      @include aspect-ratio(6, 5);
      width:100%;
      background-size: cover;
    }
		img { margin-bottom:-8px; }
	}
}
