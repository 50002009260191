/*

-------------------------------------------------------------

INTRO

*/

.intro-video{
    //background: url(../img/home-video.jpg) center no-repeat;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include aspect-ratio(16,9);
    padding:0;
    position: relative;
    .play{
        height:150px;
        width:150px;
        background: rgba($red, 0.95) url(../img/playbg.png) center no-repeat;
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.27);
        border-radius: 100%;
        top:0;
        left:0;
        right:0;
        bottom:0;
        margin:auto;
        position: absolute;
        transition:.27s;
        cursor: pointer;
        background-size: 100%;
        @include breakpoint (small){
          height: 80px;
          width:80px;
        }
        &:hover{
            background: $red url(../img/playbg.png) center no-repeat;
            height:180px;
            width:180px;
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.27);
        }
    }
}
.factory{
  .play{
      height:150px;
      width:150px;
      background: rgba($red, 0.95) url(../img/playbg.png) center no-repeat;
      box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.27);
      border-radius: 100%;
      top:0;
      left:0;
      right:0;
      bottom:0;
      margin:auto;
      position: absolute;
      transition:.27s;
      cursor: pointer;
      background-size: 100%;
      @include breakpoint (small){
        height: 80px;
        width:80px;
      }
      &:hover{
          background: $red url(../img/playbg.png) center no-repeat;
          height:180px;
          width:180px;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.27);
      }
  }
}

.featuredPageHero{
  .text{
    top: 440px;
    @include breakpoint (small){
      top:140px;
    }
  }
  .play{
      height:60px;
      width:60px;
      background: rgba($red, 0.95) url(../img/playbg.png) center no-repeat;
      box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.27);
      border-radius: 100%;
      position: relative;
      display: inline-block;
      margin-bottom: 30px;
      transition:.27s;
      cursor: pointer;
      background-size: 100%;
      @include breakpoint (small){
        // height: 80px;
        // width:80px;
      }
      &:hover{
          background: $red url(../img/playbg.png) center no-repeat;
          background-size: 100%;
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.27);
      }
    }
}
.intro {
	text-align:center;

	.line { margin-left:auto; margin-right:auto; }

	.village-block {
		margin-top:100px;
		height:590px;
		background:url(../img/model-village.jpg) top right no-repeat;
		position:relative;

		@include breakpoint(large) {
			height:500px;
			background-size:800px;
		}

		@include breakpoint(medium) {
			height:auto;
			padding-top:450px;
			background-size:700px;
			margin-top:70px;
		}

		@include breakpoint(small) {
			padding-top:350px;
		}

		.text {
			@extend .borderbox;
			text-align:left;
			padding:60px;
			position:absolute;
			background:#323232;
			width:50%;
			height:90%;
			top:5%;
			left:0px;
			color:#989898;

			@include breakpoint(large) {
				height:540px;
				top:-25px;
			}

			@include breakpoint(medium) {
				position:relative;
				width:100%;
				height:auto;
			}

			@include breakpoint(small) { padding:40px 30px; }
		}

		h3 { color:#ffffff; }

		.line {
			margin:30px 0px;
			width:60px;
		}
	}
}
