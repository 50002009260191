.step-number {
  font-size: 18px;
  color: white;
  background: #c7202e;
  /* box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.27); */
  width: 40px;
  height: 40px;
  margin: auto;
  line-height: 2.2;
  border-radius: 50%;
  margin-bottom: 40px;
}

.process-intro {
  text-align: center;
  padding: 120px 0 0;
  .line {
    margin: 40px auto;
  }
  small {
    font-size: 1em;
  }
}

.process-navigation {
  margin: 40px 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.process-link {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  a {
    display: block;
    padding: 10px 5px;
    color: white;
    background-color: darken(#f4f4f4, 40);
    @include transition(300ms ease-in-out);
    &:hover {
      background-color: darken(#f4f4f4, 20);
    }
  }
  @include screen-between(400px, 600px) {
    margin-right: 1%;
    -webkit-flex: 0 1 49%;
    -ms-flex: 0 1 49%;
    flex: 0 1 49%;
    max-width: 49%;
    &:nth-child(-n+4) {
      margin-bottom: 2%;
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @include screen-between(600px, 1251px) {
    margin-right: 1%;
    -webkit-flex: 0 1 32.333%;
    -ms-flex: 0 1 32.333%;
    flex: 0 1 32.333%;
    max-width: 32.333%;
    &:nth-child(-n+3) {
      margin-bottom: 1%;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @include screen-above(1251px) {
    margin-right: 1%;
    -webkit-flex: 0 1 15.666%;
    -ms-flex: 0 1 15.666%;
    flex: 0 1 15.666%;
    max-width: 15.666%;
    &:nth-child(6n) {
      margin-right: 0;
    }
  }
}

.process-blurb {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.preliminary {
  margin-top: 120px;
  .btn {
    padding: 0 20px;
    @include screen-above(961px) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.preliminary-content {
  max-width: 800px;
  margin: 40px auto 0;
}

.preliminary-wrapper {
  margin: 60px 0 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.preliminary-col {
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  text-align: left;
  .btn {
    background: #323232;
    @include screen-above(1251px) {
      &:first-of-type {
        margin-left: 80px;
      }
    }
  }
  @include screen-above(961px) {
    -webkit-flex: 0 1 50%;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    max-width: 50%;
    &:nth-child(2n) {
      padding-left: 75px;
    }
  }
}

.preliminary-step {
  position: relative;
  padding-left: 80px;
  margin-bottom: 40px;
  small {
    font-weight: bold;
  }
}

.preliminary-list {
  display: inline-block;
  padding: 0;
  li {
    display: block;
    @include screen-above(600px) {
      display: inline-block;
    }
  }
  .btn {
    display: block;
  }
}

.one {
  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url('../img/icons/questionmark.svg'), darken(#f4f4f4, 40);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25%;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }
}

.two {
  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url('../img/icons/hamburger.svg'), darken(#f4f4f4, 40);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30%;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }
}

.three {
  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url('../img/icons/piechart.svg'), darken(#f4f4f4, 40);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }
}

.four {
  margin-bottom: 0;
  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url('../img/icons/road.svg'), darken(#f4f4f4, 40);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40%;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
  }
}

.visit-image {
  width: 100%;
  height: 600px;
  margin: 120px 0;
}

.visit {
  padding: 0 0 120px;
}

.visit-icons {
  max-width: 800px;
  margin: 50px auto 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.visit-icon {
  -webkit-flex: 0 1 50%;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
  max-width: 50%;
  &:nth-child(-n+4) {
    margin-bottom: 40px;
  }
  img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
  }
  @include screen-between(500px, 800px) {
    -webkit-flex: 0 1 33.333%;
    -ms-flex: 0 1 33.333%;
    flex: 0 1 33.333%;
    max-width: 33.333%;
    &:nth-child(-n+3) {
      margin-bottom: 40px;
    }
  }
  @include screen-above(800px) {
    -webkit-flex: 0 1 20%;
    -ms-flex: 0 1 20%;
    flex: 0 1 20%;
    max-width: 20%;
  }
}

.design {
  margin-bottom: 120px;
}

.design-wrapper {
  margin-top: 50px;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.design-col {
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  text-align: left;
  @include screen-below(861px) {
    &:nth-child(3) {
      margin: 25px 0;
    }
  }
  @include screen-above(861px) {
    -webkit-flex: 0 1 50%;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    max-width: 50%;
    &:nth-child(-n+2) {
      margin-bottom: 40px;
    }
    &:nth-child(3) {
      -webkit-flex: 0 1 46%;
      -ms-flex: 0 1 46%;
      flex: 0 1 46%;
      max-width: 46%;
      padding-right: 4%;
    }
  }
}

.permits-wrapper {
  margin-top: 50px;
  max-width: 800px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  ul {
    margin: 0 auto;
    text-align: left;
  }
}

.permits-col {
  text-align: left;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  @include screen-above(861px) {
    -webkit-flex: 0 1 50%;
    -ms-flex: 0 1 50%;
    flex: 0 1 50%;
    max-width: 50%;
    &:nth-child(2) {
      padding-left: 60px;
    }
  }
}

.build-check {
  width: 100%;
  color: white;
  background-color: #3f954a;
  padding: 60px 0;
  margin: 120px 0;
  img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
  }
}

.move {
  width: 100%;
  color: white;
  margin-top: 120px;
  padding: 200px 0;
  img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 20px auto 0;
  }
}
