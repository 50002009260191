.team {
  margin-top: 100px;
}

.team-flex-wrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.team-member {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 100%;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
  max-width: 100%;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  text-align: center;
  h6 {
    font-size: 1em;
  }
  @include screen-between(768px, 1000px) {
    margin-right: 2%;
    -webkit-flex: 0 1 48%;
    -ms-flex: 0 1 48%;
    flex: 0 1 48%;
    max-width: 48%;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  @include screen-between(1000px, 1251px) {
    margin-right: 2%;
    -webkit-flex: 0 1 31.333%;
    -ms-flex: 0 1 31.333%;
    flex: 0 1 31.333%;
    max-width: 31.333%;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  @include screen-above(1251px) {
    margin-right: 5%;
    -webkit-flex: 0 1 20%;
    -ms-flex: 0 1 20%;
    flex: 0 1 20%;
    max-width: 20%;
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
