/*

-------------------------------------------------------------

DOWNLOAD

*/

.download {
	text-align:center;

	.btn {
		width:325px;
		text-align:left;
		margin:0px 5px;
		position: relative;
    margin-right: 65px;
		//span { float:right; }

		@include breakpoint(medium) { margin:10px 5px; }

		@include breakpoint(small) {
			margin:10px 5px;
			width:280px;
			font-size:13px;
			text-align:center;
		}
	}
}
