/* 

-------------------------------------------------------------

HIGH QUALITY

*/

.highquality {
	padding-top:0px;
	color:#989898;

	@include breakpoint(medium) { 
		text-align:center;

		.line {
			margin-left:auto;
			margin-right:auto; 
		}
	}

	.videos {
		@include clearfix;
		margin-bottom:100px;

		a {
			@include transition(all 0.2s);
			width:20%;
			display:block;
			float:left;
			opacity:0.4;
			position:relative;

			@include breakpoint(small) {
				width:50%;
			}
		}

		a:hover {
			opacity:1;
		}

		.hide-mobile {
			@include breakpoint(small) { display:none; }
		}

		span {
			display:block;
			width:20px;
			height:20px;
			position:absolute;
			right:30px;
			bottom:30px;
		}

		img { margin-bottom:-8px; }
	}

	.text {
		position:relative;
		margin-right:420px;

		@include breakpoint(medium) { margin-right:0px; }
	}

	.planbook {
		position:absolute;
		text-align:right;
		right:-420px;
		bottom:0px;

		@include breakpoint(medium) { 
			position:relative;
			bottom:auto;
			right:auto;
			text-align:center;
		}
	}

	h3 { color:#ffffff; }
}